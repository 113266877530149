<!--
 * @Description: App
 * @Author: qinleilei
 * @Date: 2023-08-25 15:22:30
 * @LastEditors: qinleilei
 * @LastEditTime: 2023-08-30 13:40:00
-->
<template>
    <a-config-provider :locale="locale">
      <router-view/>
    </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
  data () {
    return {
      locale: zhCN
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
body {
  background: url('./assets/login.svg');
}
</style>
