/*
 * @Description: store
 * @Author: qinleilei
 * @Date: 2023-08-28 14:31:25
 * @LastEditors: qinleilei
 * @LastEditTime: 2023-08-28 15:12:08
 */
import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      userInfo: {}
    }
  },
  getters: {
    userInfo: state => {
      return state.userInfo
    }
  },
  mutations: {
    setUserInfo (state, data) {
      state.userInfo = data
    }
  },
  actions: {
    setUserInfo ({ commit }, data) {
      commit('setUserInfo', data)
    }
  },
  modules: {
  }
})
