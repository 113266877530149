/*
 * @Description: route
 * @Author: qinleilei
 * @Date: 2023-08-25 15:22:30
 * @LastEditors: qinleilei
 * @LastEditTime: 2023-08-31 13:45:02
 */
import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/HomeView.vue'),
    children: [
      {
        path: '/student-manage',
        name: 'StudentManage',
        component: () => import('../views/manage/student-manage/index.vue')
      },
      {
        path: '/teacher-manage',
        name: 'TeacherManage',
        component: () => import('../views/manage/teacher-manage/index.vue')
      },
      {
        path: '/banner-manage',
        name: 'BannerManage',
        component: () => import('../views/manage/banner-manage/index.vue')
      },
      {
        path: '/lesson-manage',
        name: 'LessonManage',
        component: () => import('../views/manage/lesson-manage/index.vue')
      },
      {
        path: '/question-card-manage',
        name: 'QuestionCardManage',
        component: () => import('../views/manage/question-card-manage/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const store = useStore()
  if (Object.is(to.path, '/login')) {
    next()
    return
  }
  if (store.getters.userInfo.username) {
    next()
  } else {
    next('/login')
  }
})

export default router
