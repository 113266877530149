/*
 * @Description: To edit
 * @Author: qinleilei
 * @Date: 2023-08-25 15:22:30
 * @LastEditors: qinleilei
 * @LastEditTime: 2023-08-29 10:39:17
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import * as Icons from '@ant-design/icons-vue'
const icons = Icons
// 页面刷新时，将vuex数据存入sessionStorage，完成后，将sessionStorage中的数据删除
window.addEventListener('beforeunload', () => {
  sessionStorage.setItem('stateInfo', JSON.stringify(store.state))
})
if (sessionStorage.getItem('stateInfo')) {
  store.replaceState(
    Object.assign(
      {},
      store.state,
      JSON.parse(sessionStorage.getItem('stateInfo'))
    )
  )
}
sessionStorage.removeItem('stateInfo')
const app = createApp(App)
for (const i in icons) {
  app.component(i, icons[i])
}
app.use(store).use(router).use(Antd).mount('#app')
